$appt_booking: true;

$cr20: true;

$jp-primary-font: 'Tazugane', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo,
  'ＭＳ Ｐゴシック', 'MS PGothic', Arial, Helvetica, sans-serif;

$primary-font: $jp-primary-font;
$font-meta: $jp-primary-font;
$primary-font-rev: $jp-primary-font;

$header-height-desktop: 138px;
